import React, { useMemo } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { useIsMobile } from '@fingo/lib/hooks';
import { formatMoney } from '@fingo/lib/helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useHomeReceivedData } from '../../hooks';

const HomeInvoicesReceived = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const {
    rejectedInvoicesCount,
    rejectedInvoicesAmount,
    receivedInvoicesCount,
    receivedInvoicesAmount,
    creditNotesInvoicesCount,
    creditNotesInvoicesAmount,
    loading,
  } = useHomeReceivedData();

  const boxes = useMemo(() => [
    {
      key: 'home-rejected-invoices',
      icon: <CloseIcon />,
      title: 'Facturas Rechazadas',
      count: rejectedInvoicesCount,
      amount: `$${formatMoney(rejectedInvoicesAmount)}`,
      link: '/app/sales/accountable?siiStatus=%5B%22Rejected%22%5D',
      loading,
    },
    {
      key: 'home-received-invoices',
      icon: <SaveAltIcon />,
      title: 'Facturas recibidas',
      count: receivedInvoicesCount,
      amount: `$${formatMoney(receivedInvoicesAmount)}`,
      link: '/app/purchases/payable',
      loading,
    },
    {
      key: 'home-credit-notes',
      icon: <SwapHorizIcon />,
      title: 'Notas de crédito',
      count: creditNotesInvoicesCount,
      amount: `$${formatMoney(creditNotesInvoicesAmount)}`,
      link: '/app/sales/accountable?documentType=%5B61%5D',
      loading,
    },
  ], [
    rejectedInvoicesCount,
    rejectedInvoicesAmount,
    receivedInvoicesCount,
    receivedInvoicesAmount,
    creditNotesInvoicesCount,
    creditNotesInvoicesAmount,
    loading,
  ]);

  return (
    <Stack mb={2} maxWidth={1085} mx="auto">
      <Typography variant="h5" fontWeight={!isMobile && 600} alignSelf="center" py={2} color={isMobile && 'text.contrastText'}>
        Esta semana has recibido:
      </Typography>
      <Paper sx={{ backgroundColor: isMobile ? 'text.main' : 'white', justifySelf: 'center', borderRadius: 4 }}>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
          {boxes.map((box, index) => (
            <Box
              key={box.key}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              bgcolor="fingoWhite.main"
              borderRadius={4}
              spacing={1}
              m={1}
              onClick={() => history.push(box.link)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: '#E62E66',
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.15s ease-in-out',
              }}
            >
              {index !== 0 && <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />}
              <Stack sx={{ px: 3, py: isMobile ? 2 : 4 }} alignItems="left" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Badge sx={{ p: 0.5, bgcolor: 'primary.main', color: 'white', borderRadius: 4 }}>
                    {box.icon}
                  </Badge>
                  <Typography variant="h5" fontWeight={500}>{box.title}</Typography>
                </Stack>
                {box.loading ? (
                  <Box sx={{ width: '50%', mr: 1, alignSelf: 'center', justifySelf: 'center' }}>
                    <LinearProgress color="gray" />
                  </Box>
                ) : (
                  <Stack direction="row" alignSelf="center">
                    <Typography variant="h5">{box.count}</Typography>
                    <Typography variant="h5" mx={1}>|</Typography>
                    <Typography variant="h5">{box.amount}</Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          ))}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default HomeInvoicesReceived;
