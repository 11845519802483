import React from 'react';
import moment from 'moment';
import { orderingInvoiceIssuedSelected } from '@fingo/lib/apollo/reactive-variables/purchase-orders';
import { orderingSimulationSelection } from '@fingo/lib/apollo/cache/localQuery';
import { CompanyCell, TextCell } from '@fingo/lib/components/cells';
import { GridEditDateCell } from '@fingo/lib/components/dataGridCells';
import { formatDayMonthYear, formatMoney } from '@fingo/lib/helpers';
import { useQuery } from '@apollo/client';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import OrderingOperationActionButton from '../ordering/ordering-operation/OrderingOperationActionButton';
import OrderingStatus from '../ordering/status';
import useWidth from './useWidth';

const useOrderingPreColumns = () => {
  const {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    orderingOperationStatus,
    buttonWidth,
    orderNumberWidth,
    littleMessageStatus,
  } = useWidth();
  const dateToPayOnChange = (row, newValue) => orderingInvoiceIssuedSelected(
    { ...orderingInvoiceIssuedSelected(), [row.id]: newValue },
  );
  const { data: { orderingInvoiceIssuedSelected: invSelectedQuery },
  } = useQuery(orderingSimulationSelection);
  return [
    {
      field: 'orderNumber',
      headerName: 'Número',
      width: orderNumberWidth,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => (
        <TextCell
          text={row.orderNumber}
          variant="body2"
          noWrap
          width="inherit"
        />
      ),
    },
    {
      field: 'purchaser_Name',
      headerName: 'Comprador',
      flex: 1.3,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => <CompanyCell company={row.purchaser} />,
    },
    {
      field: 'orderingInvoiceDate',
      headerName: 'Fecha de emisión factura',
      ...GRID_DATE_COL_DEF,
      headerAlign: 'left',
      align: 'left',
      width: dateInputWidth,
      filterable: false,
      sortable: false,
      renderEditCell: (params) => (
        <GridEditDateCell
          {...params}
          onChange={dateToPayOnChange}
          minDate={moment().add(15, 'days')}
          maxDate={params.row.dateExpiration.clone().add(params.row.offer.fundingDaysAfterExpiration, 'days')}
        />
      ),
      renderCell: (params) => (
        <GridEditDateCell
          {...params}
          value={invSelectedQuery[params.row.id]}
          onChange={dateToPayOnChange}
          minDate={moment().startOf('day').add(7, 'days')}
          maxDate={moment().startOf('day').add(120, 'days')}
        />
      ),
    },
    {
      field: 'publicationDate',
      headerName: 'Fecha OC',
      width: dateWidth,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => (
        <TextCell
          text={formatDayMonthYear(row.publicationDate)}
          variant="body2"
        />
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Monto total',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: true,
      width: amountWidth,
      renderCell: ({ row }) => (
        <TextCell
          text={`$${formatMoney(row.totalAmount)}`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingsimulation_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`$${formatMoney(row.orderingsimulation.orderingPaymentAmount)}`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingsimulation_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`${row.orderingsimulation.orderingMonthlyRate.toFixed(2).replace('.', ',')}%`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingsimulation_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`${(100 - row.orderingsimulation.orderingRetentionRate).toFixed(2).replace('.', ',')}%`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingStatus',
      headerName: 'Estado',
      width: littleMessageStatus,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <TextCell text={row.simulationSelectable.status} variant="body2" noWrap />
      ),
    },
    {
      field: 'orderingoffer_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`$${formatMoney(row.orderingoffer.orderingPaymentAmount)}`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingoffer_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`${row.orderingoffer.orderingMonthlyRate.toFixed(2).replace('.', ',')}%`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingoffer_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <TextCell
          text={`${(100 - row.orderingoffer.orderingRetentionRate).toFixed(2).replace('.', ',')}%`}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingoffer_InvoiceIssuedDate',
      headerName: 'Emisión factura',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <TextCell
          text={formatDayMonthYear(params.row.orderingoffer.invoiceIssuedDate)}
          variant="body2"
        />
      ),
    },
    {
      field: 'orderingOperationStatus',
      headerName: 'Estado',
      width: orderingOperationStatus,
      headerAlign: 'left',
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => <OrderingStatus status={row.status} />,
    },
    {
      field: 'orderingOperationActionOffer',
      headerName: '',
      width: buttonWidth,
      headerAlign: 'left',
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <OrderingOperationActionButton purchaseOrder={params.row} />
      ),
    },
    {
      field: 'debtDays',
      headerName: 'Días de mora',
      sortable: true,
      renderCell: ({ row }) => (
        <TextCell
          text={`${row.orderingDebt?.days || 0}`}
          variant="body2"
        />
      ),
    },
  ];
};

export default useOrderingPreColumns;
