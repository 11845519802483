import React, { useState } from 'react';
import Link from '@mui/material/Link';
import PasswordRecoveryDialog from '../dialogs/PasswordRecoveryDialog';

const PasswordRecoveryLink = () => {
  const [openPasswordRecoveryDialog, setOpenPasswordRecoveryDialog] = useState(false);

  return (
    <>
      <Link
        underline="hover"
        variant="body2"
        color="text"
        sx={{
          display: 'block',
          textAlign: 'center',
        }}
        onClick={() => setOpenPasswordRecoveryDialog(true)}
        id="login-forgot"
      >
        ¿Olvidaste tu contraseña?
      </Link>
      {openPasswordRecoveryDialog && (
        <PasswordRecoveryDialog
          open={openPasswordRecoveryDialog}
          onClose={() => setOpenPasswordRecoveryDialog(false)}
        />
      )}
    </>
  );
};

export default React.memo(PasswordRecoveryLink);
