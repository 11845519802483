import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIsMobile } from '@fingo/lib/hooks';
import { useHistory } from 'react-router-dom';
import FingoDataGrid from '@fingo/lib/components/dataGrids/FingoDataGrid';
import { DEFAULT_ROW_HEIGHT } from '@fingo/lib/constants';

const HomeAvailableOffers = ({ offers, columns, includeHeaders, viewOffersLink, docType }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const displayOffers = offers.slice(0, 2);
  const gridHeight = displayOffers.length * (DEFAULT_ROW_HEIGHT + 8) + 50;

  return (
    <>{
      isMobile ? (
        <Typography
          variant="h4"
          onClick={() => { history.push(`${viewOffersLink}`); }}
          sx={{ color: 'background.light',
            alignSelf: 'center',
            my: 1,
            pt: 1,
            textDecoration: 'underline',
          }}
        >
          Ver ofertas
        </Typography>
      ) : (
        <Stack
          sx={{
            alignItems: 'center',
            bgcolor: 'background.dark',
            borderRadius: 4,
            height: '40%',
            width: '100%',
          }}
        >
          <Box display="flex" alignItems="center" py={1}>
            <Typography variant="h2" fontWeight={700} sx={{ flex: 1 }}>
              ¡Tienes ofertas disponibles!
            </Typography>
            <Typography variant="h2" display="inline" sx={{ ml: 0.5 }}>
              Las siguientes {docType} fueron aprobadas:
            </Typography>
          </Box>
          <Box height={gridHeight} width="100%" sx={{ bgcolor: 'background.light', borderRadius: 4 }}>
            <FingoDataGrid
              disableSelectionOnClick
              includeHeaders={includeHeaders}
              rows={displayOffers}
              columns={columns}
              pageSize={2}
              autoHeight
              hideFooter
              maxHeight="100%"
            />
          </Box>
          <Button
            variant="contained"
            onClick={() => { history.push(`${viewOffersLink}`); }}
            sx={{ bgcolor: 'primary.main', alignSelf: 'end', my: 1 }}
            size="small"
          >
            Ver ofertas
          </Button>
        </Stack>
      )
}
    </>
  );
};

HomeAvailableOffers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  offers: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  includeHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  viewOffersLink: PropTypes.string.isRequired,
  docType: PropTypes.string,
};

HomeAvailableOffers.defaultProps = {
  docType: 'facturas',
};

export default HomeAvailableOffers;
